import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';


const debug = true;


export default function BrainDomains(props) {
    const [value, setValue] = React.useState('female');


    function handleChange(e) {
        setValue(e.target.value);
        //  console.log(e.target.value);
        if (e.target.value === 'yes') {
            //props.domains.toString(',')
            props.parentCallBack(props.domains, props.id);
        }

        if (e.target.value === 'not_sure') {
            props.parentCallBackNotSure(props.domains, props.id);
        }

        if (e.target.value === 'no') {
            props.parentCallBackNo(props.domains, props.id);
        }
    }


    return (
        <Box sx={{ minWidth: 100, p:1}}>
                  <Card variant="outlined">
        
        <CardContent>
            
             <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{props.id}. {props.question}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
          onChange={handleChange}
           value={value}
      >
       <FormControlLabel value={'yes'} control={<Radio />} label="yes" />
        <FormControlLabel value="no" control={<Radio />} label="no" />
       <FormControlLabel value={'not_sure'} control={<Radio />} label="not sure" />
        
      </RadioGroup>
    </FormControl>
            
    </CardContent>
        
                         </Card>
    
    
                </Box>


    );
}
