import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Myalert from './Myalert';


const bull = (
    <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
    <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
      Oppositional Defiant Disorder
      </Typography>
      <Typography variant="h5" component="div">
        ODD
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
      Behavior disorder in which a child displays a pattern of an angry or cranky mood, defiant 
      or combative behavior, and vindictiveness toward people in authority
      </Typography>
      <Typography variant="body2">
          Read more at  
          <Typography variant="title" color="inherit" noWrap>
    &nbsp;
  </Typography>
            <Link 
                href="https://www.webmd.com/mental-health/oppositional-defiant-disorder"
                underline="always" target="new">
                                {'webmd.com'}
                              </Link>
      </Typography>
    </CardContent>
   
  </React.Fragment>
);

const preventDefault = (event) => event.preventDefault();

const debug = false;

export default function OutlinedCard(props) {

    /*
    
       {
     1: 'no', //adaptive 
     2: 'no', //executive
     3: 'no', // attention
     4: 'no', //affect
     5: 'no', // academic
     6: 'no', //language
     7: 'no', //cognition
     8: 'no', //memory 
     9: 'no', //motor
     10: 'no', // sensory
     11: 'no', // physical
     12: 'no', //brain
   }
    
    */


    //  console.log('Fasd props', props.results);
    let score = 0;
    let scoreNotSure = 0;
    for (let [key, value] of Object.entries(props.results)) {
        // console.log(key, value);
        if (key === "1") {
            if (value === 'yes' || value === 'not sure') { //adaptive
                //  score = score + 1:
                score = score + 1;
            }
        }
        if (key === "2") {
            if (value === 'yes' || value === 'not sure') { //executive
                //  score = score + 1:
                score = score + 1;
            }
        }

        if (key === "4") {
            if (value === 'yes' || value === 'not sure') { //affect
                //  score = score + 1:
                score = score + 1;
            }
        }

       if(debug === true){ console.log('ASD yes: ' + score, ', not sure: ' + scoreNotSure) };
    }

    function getContent(score, scoreNotSure) {
        if ((score + scoreNotSure) > 1) {
            return (
                  <Box sx={{ minWidth: 200, p:1}}>
                  <Card variant="outlined">{card}
                  <Myalert conditionName={"ODD"} results={props.results} score={score} scoreNotSure={scoreNotSure} maxScore={3} />
                  </Card>
                </Box>
            );
        }
        else {
            return (<div />)
        }
    }

    return (
        getContent(score, scoreNotSure)
    );
}
