import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';
import { API, graphqlOperation  } from 'aws-amplify'
import { createMessage } from '../../src/graphql/mutations'



export default function FormPropsTextFields() {
    
    
     ReactGA.initialize('UA-221606234-1', {
        debug: false,
        titleCase: false,
      });
      
       ReactGA.pageview(window.location.pathname + window.location.search);
       
       
    
    const [payload, setpayload] = React.useState({});
    const [count, setCount] = React.useState(0);
    
    async function sendMessage(){
        console.log('sending email');
        alert('message sent');
        await API.graphql(graphqlOperation(createMessage, {input: payload}));
        
        
        setpayload({name:'', email: '', message: ''});
        setCount(count+1);
    }

    function nameChange(e){
        payload.name = e.target.value;
        setpayload(payload);
   //     console.log(payload);
    }
    

    function emailChange(e){
        payload.email = e.target.value;
        setpayload(payload);
    //    console.log(payload);
    }
    
        function messageChange(e){
        payload.message = e.target.value;
        setpayload(payload);
      //  console.log(payload);
    }

    
    return (
        <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
    
    
        <Helmet>
            <title>Get in touch to discuss kids developmental concerns</title>
            <meta 
                name="simple diangnostic tool for developmental delays" 
                content="Get in touch to discuss child developmental concerns simple diagnsotic tool." />
        </Helmet>
    
      <div>
        <TextField
          required
          id={"outlined-required"+count}
          label="name"
          value={payload.name}
          onChange={nameChange}
        />
        
         <TextField
          required
          id={"outlined-required"+count}
          label="email"
          value={payload.email}
           onChange={emailChange}
        />
        
        
  <TextField
            required
          id={"outlined-multiline-static"+count}
          label="Message"
          multiline
          rows={4}
          value={payload.message}
          onChange={messageChange}
        />
        
         <Button variant="contained"
            onClick={() => {
                    sendMessage();
              }}
         
         >Send</Button>
       
      </div>
    </Box>
    );
}
