import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Myalert from './Myalert';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent >
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       Acquired brain injury
      </Typography>
      <Typography variant="h5" component="div">
        ABI
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
      An acquired brain injury (ABI) is any damage to the brain that happens after birth.
      </Typography>
      <Typography variant="body2">
          Read more at  
          <Typography variant="title" color="inherit" noWrap>
    &nbsp;
  </Typography>
            <Link 
                href="https://raisingchildren.net.au/guides/a-z-health-reference/abi"
                underline="always" target="new">
                                {'raisingchildren.net.au'}
                              </Link>
      </Typography>
    </CardContent>
    <br /><br/>
  </React.Fragment>
);

const preventDefault = (event) => event.preventDefault();

const debug=false;

export default function OutlinedCard(props) {
  //  console.log('Fasd props', props.results);
    let score = 0;
    let scoreNotSure = 0;
    for (let [key, value] of Object.entries( props.results)) {
       // console.log(key, value);
       if(value==='yes'){
         //  score = score + 1:
         score = score + 1;
       }
       if(value==='not sure'){
         //  score = score + 1:
         scoreNotSure = scoreNotSure + 1;
       }
      if(debug===true){ console.log('yes: '+ score, ', not sure: ' + scoreNotSure ) };
    }
    
    function getContent(score, scoreNotSure){
        if((score+scoreNotSure)>0){
            return (
                  <Box sx={{ minWidth: 200, p:1}}>
                  <Card variant="outlined">{card}
                 <Myalert conditionName={"ABI"} results={props.results} score={score} scoreNotSure={scoreNotSure} maxScore={12} />
                  </Card>
                </Box>
              );
        }else{
            return(<div />)
        }
    }
    
  return (
     getContent(score, scoreNotSure) 
  );
}
