import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Myalert from './Myalert';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent >
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Reading disability
      </Typography>
      <Typography variant="h5" component="div">
        Dyslexia
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
      A learning disorder that involves difficulty reading due to problems identifying speech sounds 
      and learning how they relate to letters and words (decoding)
      </Typography>
      <Typography variant="body2">
          Read more at  
          <Typography variant="title" color="inherit" noWrap>
    &nbsp;
  </Typography>
            <Link 
                href="https://dyslexiaassociation.org.au"
                underline="always" target="new">
                                {'dyslexiaassociation.org.au'}
                              </Link>
      </Typography>
    </CardContent>
    <br /><br/>
  </React.Fragment>
);

const preventDefault = (event) => event.preventDefault();

const debug=false;

export default function OutlinedCard(props) {
  //  console.log('Fasd props', props.results);
    let score = 0;
    let scoreNotSure = 0;
    for (let [key, value] of Object.entries( props.results)) {
        if(key==="5" || key==="6"  || key==="7" || key==="8"){
            // console.log(key, value);
               if(value==='yes'){
                 //  score = score + 1:
                 score = score + 1;
               }
               if(value==='not sure'){
                 //  score = score + 1:
                 scoreNotSure = scoreNotSure + 1;
               }
        }
       
      if(debug===true){ console.log('yes: '+ score, ', not sure: ' + scoreNotSure ) };
    }
    
    function getContent(score, scoreNotSure){
        if((score+scoreNotSure)>0){
            return (
                  <Box sx={{ minWidth: 200, p:1}}>
                  <Card variant="outlined">{card}
                 <Myalert conditionName={"Dyslexia"} results={props.results} score={score} scoreNotSure={scoreNotSure} 
                    maxScore={4} />
                  </Card>
                </Box>
              );
        }else{
            return(<div />)
        }
    }
    
  return (
     getContent(score, scoreNotSure) 
  );
}
