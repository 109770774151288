import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import Contact from './views/Contact';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

import BrainBodyDomains from './BrainBodyDomains';
import ConditionViewer from './components/ConditionViewer';

Amplify.configure(awsExports);

// react router info: https://reactrouter.com/docs/en/v6
     ReactGA.initialize('UA-221606234-1', {
        debug: false,
        titleCase: false,
      });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
       <Route path="/" element={<App />} />
        <Route path="contact" element={<Contact />} />
        <Route path="condition/SAS" element={<ConditionViewer condition={'SAS'} />} />
        </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
