import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import BrainDomains from './components/BrainDomains';
import Fasd from './components/Fasd';
import Abi from './components/Abi';
import Asd from './components/Asd';
import Adhd from './components/Adhd';
import Odd from './components/Odd';
import Anxietydepressionptsd from './components/Anxietydepressionptsd';
import Depression from './components/Depression';
import Ptsd from './components/Ptsd';
import Dmd from './components/Dmd';
import Ied from './components/Ied';
import Sld from './components/Sld';
import Id from './components/Id';
import Spd from './components/Spd';
import Rad from './components/Rad';
import Pandas from './components/Pandas';
import Dyslexia from './components/Dyslexia';
import Sas from './components/Sas';
import {ADHD, ABI, ANXIETY, ASD, DEPRESSION, DMD, DYSLEXIA, FASD, ID, IED, ODD, PANDAS, RAD, SLD, SPD, SAS} from './components/CausesCalculator';

import { ThemeProvider, createTheme } from '@mui/system';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Outlet, Link } from "react-router-dom";
import Link2 from '@mui/material/Link';
import ReactGA from 'react-ga';

//import {matchSorter} from 'match-sorter';


//const sortArray = require('sort-array');
var arraySort = require('array-sort');


// https://flatuicolors.com/palette/defo

const debug = false;

export default function BrainBodyDomains() {
    const [results, setResults] = React.useState({
        1: 'no', //adaptive 
        2: 'no', //executive
        3: 'no', // attention
        4: 'no', //affect
        5: 'no', // academic
        6: 'no', //language
        7: 'no', //cognition
        8: 'no', //memory 
        9: 'no', //motor
        10: 'no', // sensory
        11: 'no', // physical
        12: 'no', //brain
    });
    
    const [causesResults, setCausesResults] =  React.useState([
        {
            cause: 'ADHD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}
        },
        {
            cause: 'ABI',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}
        },
        {
            cause: 'Anxiety',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}
        },
        {
            cause: 'ASD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}
        },
        {
            cause: 'Depression',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}
        },
        {
            cause: 'DMD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}
        },
        {
            cause: 'Dyslexia',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        },
        {
            cause: 'FASD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        },
        {
             cause: 'ID',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        },
        {
             cause: 'IED',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        },
        {
           cause: 'ODD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        }, 
        {
             cause: 'PANDAS',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        },       
        {
            cause: 'RAD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        }, 
        {
            cause: 'SLD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        },
        {
            cause: 'SPD',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        }, 
        {
            cause: 'SAS',
            pctMatchCause: 0,
            pctMatchTotal: 0,
            results: {}            
        },
    ]);
    
    

    const [count, setCount] = React.useState(0);


    function recalculate(){
        
        
        let ADHDresults = ADHD(results);
        causesResults[getKeyByValue(causesResults, 'ADHD')]['results'] =  ADHDresults;
        causesResults[getKeyByValue(causesResults, 'ADHD')]['pctMatchTotal'] =  ADHDresults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'ADHD')]['pctMatchCause'] =  ADHDresults.pctMatchCause;
        causesResults[getKeyByValue(causesResults, 'ADHD')]['reactObj'] =   <Adhd id={results.toString()} results={results} sx={{ p:1 }}/>;
        
        
        let ABIresults = ABI(results);
        causesResults[getKeyByValue(causesResults, 'ABI')]['results'] =  ABIresults;
        causesResults[getKeyByValue(causesResults, 'ABI')]['pctMatchTotal'] =  ABIresults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'ABI')]['pctMatchCause'] =  ABIresults.pctMatchCause;
         causesResults[getKeyByValue(causesResults, 'ABI')]['reactObj'] =   <Abi id={results.toString()} results={results}/> 
        
        let AnxietyResults = ANXIETY(results);
        causesResults[getKeyByValue(causesResults, 'Anxiety')]['results'] =  AnxietyResults;
        causesResults[getKeyByValue(causesResults,  'Anxiety')]['pctMatchTotal'] =  AnxietyResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'Anxiety' )]['pctMatchCause'] =  AnxietyResults.pctMatchCause;
         causesResults[getKeyByValue(causesResults, 'Anxiety' )]['reactObj'] =  <Anxietydepressionptsd id={results.toString()} results={results}/>
        
        let ASDResults = ASD(results);
        causesResults[getKeyByValue(causesResults, 'ASD' )]['results'] =  ASDResults;
        causesResults[getKeyByValue(causesResults,  'ASD')]['pctMatchTotal'] =  ASDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'ASD' )]['pctMatchCause'] =  ASDResults.pctMatchCause;
         causesResults[getKeyByValue(causesResults, 'ASD' )]['reactObj'] =   <Asd id={results.toString()} results={results}/>
        
        let DepressionResults = DEPRESSION(results);
        causesResults[getKeyByValue(causesResults, 'Depression')]['results'] =  DepressionResults;
        causesResults[getKeyByValue(causesResults, 'Depression')]['pctMatchTotal'] =  DepressionResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'Depression')]['pctMatchCause'] =  DepressionResults.pctMatchCause;
        causesResults[getKeyByValue(causesResults,'Depression' )]['reactObj'] =   <Depression id={results.toString()} results={results}/>
         
        
        let DMDResults = DMD(results);
        causesResults[getKeyByValue(causesResults, 'DMD')]['results'] =  DMDResults;
        causesResults[getKeyByValue(causesResults, 'DMD')]['pctMatchTotal'] =  DMDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults,'DMD' )]['pctMatchCause'] =  DMDResults.pctMatchCause;
        causesResults[getKeyByValue(causesResults, 'DMD')]['reactObj'] =   <Dmd id={results.toString()} results={results}/>
        
        let DyslexiaResults = DYSLEXIA(results);
        causesResults[getKeyByValue(causesResults, 'Dyslexia')]['results'] =  DyslexiaResults;
        causesResults[getKeyByValue(causesResults,  'Dyslexia')]['pctMatchTotal'] =  DyslexiaResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults,  'Dyslexia')]['pctMatchCause'] =  DyslexiaResults.pctMatchCause;   
         causesResults[getKeyByValue(causesResults,  'Dyslexia')]['reactObj'] =   <Dyslexia id={results.toString()} results={results}/>
          
        let FASDResults = FASD(results);
        causesResults[getKeyByValue(causesResults,  'FASD')]['results'] =  FASDResults;
        causesResults[getKeyByValue(causesResults,  'FASD')]['pctMatchTotal'] =  FASDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults,  'FASD')]['pctMatchCause'] =  FASDResults.pctMatchCause;          
        causesResults[getKeyByValue(causesResults,  'FASD')]['reactObj'] =   <Fasd id={results.toString()} results={results}/>

        let IDResults = ID(results);
        causesResults[getKeyByValue(causesResults, 'ID' )]['results'] =  IDResults;
        causesResults[getKeyByValue(causesResults, 'ID' )]['pctMatchTotal'] =  IDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults,  'ID')]['pctMatchCause'] =  IDResults.pctMatchCause;       
        causesResults[getKeyByValue(causesResults,  'ID')]['reactObj'] =   <Id id={results.toString()} results={results}/>
        
        let IEDResults = IED(results);
        causesResults[getKeyByValue(causesResults, 'IED')]['results'] =  IEDResults;
        causesResults[getKeyByValue(causesResults, 'IED' )]['pctMatchTotal'] =  IEDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults,  'IED')]['pctMatchCause'] =  IEDResults.pctMatchCause; 
        causesResults[getKeyByValue(causesResults,  'IED')]['reactObj'] =    <Ied id={results.toString()} results={results}/>
        
        let ODDResults = ODD(results);
        causesResults[getKeyByValue(causesResults, 'ODD' )]['results'] =  ODDResults;
        causesResults[getKeyByValue(causesResults,'ODD' )]['pctMatchTotal'] =  ODDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults,'ODD' )]['pctMatchCause'] =  ODDResults.pctMatchCause; 
        causesResults[getKeyByValue(causesResults,'ODD' )]['reactObj'] =  <Odd id={results.toString()} results={results}/>
        
        let PANDASResults = PANDAS(results);
        causesResults[getKeyByValue(causesResults, 'PANDAS')]['results'] =  PANDASResults;
        causesResults[getKeyByValue(causesResults,'PANDAS' )]['pctMatchTotal'] =  PANDASResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults,'PANDAS' )]['pctMatchCause'] =  PANDASResults.pctMatchCause; 
        causesResults[getKeyByValue(causesResults, 'PANDAS')]['reactObj'] =   <Pandas id={results.toString()} results={results}/>
        
        let RADResults = RAD(results);
        causesResults[getKeyByValue(causesResults, 'RAD')]['results'] =  RADResults;
        causesResults[getKeyByValue(causesResults, 'RAD')]['pctMatchTotal'] =  RADResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'RAD')]['pctMatchCause'] =  RADResults.pctMatchCause; 
        causesResults[getKeyByValue(causesResults, 'RAD')]['reactObj'] =   <Rad id={results.toString()} results={results}/>
         
        let SLDResults = SLD(results);
        causesResults[getKeyByValue(causesResults, 'SLD' )]['results'] =  SLDResults;
        causesResults[getKeyByValue(causesResults, 'SLD')]['pctMatchTotal'] =  SLDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'SLD')]['pctMatchCause'] =  SLDResults.pctMatchCause;         
        causesResults[getKeyByValue(causesResults,'SLD' )]['reactObj'] =  <Sld id={results.toString()} results={results}/>
        
        let SPDResults = SPD(results);
        causesResults[getKeyByValue(causesResults, 'SPD')]['results'] =  SPDResults;
        causesResults[getKeyByValue(causesResults, 'SPD')]['pctMatchTotal'] =  SPDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'SPD')]['pctMatchCause'] =  SPDResults.pctMatchCause; 
        causesResults[getKeyByValue(causesResults, 'SPD')]['reactObj'] =  <Spd id={results.toString()} results={results}/>
        
        let SasResults = SAS(results);
        causesResults[getKeyByValue(causesResults, 'SAS')]['results'] =  SPDResults;
        causesResults[getKeyByValue(causesResults, 'SAS')]['pctMatchTotal'] =  SPDResults.pctMatchTotal;
        causesResults[getKeyByValue(causesResults, 'SAS')]['pctMatchCause'] =  SPDResults.pctMatchCause; 
        causesResults[getKeyByValue(causesResults, 'SAS')]['reactObj'] =  <Sas id={results.toString()} results={results}/>
        
      //   if(debug===true){  console.log('causesResults', causesResults) }
      //  matchSorter(causesResults, '', {keys: ['pctMatchTotal', 'pctMatchCause']});
        
        /*
        sortArray(causesResults, {
          by: ['pctMatchTotal', 'pctMatchCause'],
          order: 'desc'
        })
        */
        
         if(debug===true){  console.log('causesResults presort', causesResults) }
         
         
        arraySort(causesResults,  ['pctMatchTotal', 'pctMatchCause'],  {reverse: true});
        
        setCausesResults(causesResults);
        
      if(debug===true){  console.log('causesResults', causesResults) }
    }
    
    function getKeyByValue(objects, valueSearch) {
      //  console.log('getKeyByValue', objects, valueSearch);
         
       for (let [key, value] of Object.entries( objects)) {
        //   /  console.log('getKeyByValue', value);
           if(value.cause === valueSearch){
           // console.log('getKeyByValue', key);
            return key;
           }
       }
    }

    function childCallBack(domains, id) {
        //  console.log('yes', id,  domains);
        results[id] = 'yes';
        setResults(results);
        setCount(count + 1);

        ReactGA.event({
            category: 'difficult-domain-' + id,
            action: 'yes',
        });
        
        recalculate();
    }

    function childCallBackNotSure(domains, id) {
        //  console.log('not sure', id,  domains);
        results[id] = 'not sure';
        setResults(results);
        setCount(count + 1);
        //  console.log(results);
        
        ReactGA.event({
            category: 'difficult-domain-' + id,
            action: 'not sure',
        });

        recalculate();
    }

    // need to clear the No from the state to avoid including suggestions for these
    function childCallBackNo(domains, id) {
        //  console.log('no', id,  domains);
        results[id] = 'no';
        setResults(results);
        setCount(count + 1);
        //  console.log(results);

        ReactGA.event({
            category: 'difficult-domain-' + id,
            action: 'no',
        });
        
        recalculate();

    }


    let itemList=causesResults.map((item,index)=>{
      return item.reactObj;
    })
    
    /*
    //previous componet list, now auto sorted based on match
    
     <Adhd id={results.toString()} results={results} sx={{ p:1 }}/>
            <Fasd id={results.toString()} results={results}/>
          <Asd id={results.toString()} results={results}/>
           <Odd id={results.toString()} results={results}/>
            <Anxietydepressionptsd id={results.toString()} results={results}/>
            <Depression id={results.toString()} results={results}/>
            <Ptsd id={results.toString()} results={results}/>
             <Dmd id={results.toString()} results={results}/>
             <Ied id={results.toString()} results={results}/>
             <Sld id={results.toString()} results={results}/>
             <Id id={results.toString()} results={results}/>
              <Spd id={results.toString()} results={results}/>
              <Rad id={results.toString()} results={results}/>
               <Pandas id={results.toString()} results={results}/>
               <Dyslexia id={results.toString()} results={results}/>
        <Abi id={results.toString()} results={results}/>
    
    */

    return (
        <div style={{ width: '100%' }}>
    
    <Box
      sx={{
        width: '100%',
        p: 1
      }}
    >
    
         <Box
      sx={{
        width: '98%',
        color: '#ecf0f1',
        p: 1,
        '& > .MuiBox-root > .MuiBox-root': {
          p: 1,
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 1,
          gridTemplateRows: 'auto',
          gridTemplateAreas: `"header header header header"
        "main sidebar sidebar sidebar"
        "footer footer footer footer"`,
        }}
      >
        <Box sx={{ gridArea: 'header', bgcolor: '#2c3e50' }}>
            A simple tool for researching child developmental 
            concerns based on what parents experience.
            
         <br /><br />
            This tool does not intend and cannot make any diagnosis for your child. It is a tool to assist parents in identifying possible causes 
            so that you can seek professional assistance as soon as possible. You should always seek professional help. This tool does not cover all 
            possible causes of developmental issues in children.
        <br />     <br />
            When it comes to child developmental causes, early intervention is key to improving the life prospects
            of the child. Hopefully this tool will help navigate the possible causes.
        <br />   <br /> 
         This tool does not collect or store any data that can identify you.
        <br />
        <br /> <b r/> 
            Please take a moment to  tell us
            
           what you think of this tool and how we can improve it.  
          
          <Typography variant="title" color="inherit" noWrap>
    &nbsp;
  </Typography>
               <Link2 href="/contact" underline="always">
                                {'Get in touch with us'}
                              </Link2>
           
          
        
        </Box>
        <Box sx={{ gridArea: 'main', bgcolor: '#34495e' }}>
        

        <BrainDomains 
          id={1}
          question={"Does your child have difficulty with social skills, taking care of themselves, concepts of time, dates or money?"}
          domains={["adaptive"]}
          parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
           parentCallBackNo={childCallBackNo}
          />
        <BrainDomains 
         id={2}
          question={"Is your child impulsive or is unable to connect cause and effect or claim to not remember things regularly?"}
           domains={["attention"]}
            parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          />
         <BrainDomains 
         id={3}
          question={"Is your child unable to maintain their attention to a task at a level considered age appropriate?"}
           domains={["attention"]}
            parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          />
           <BrainDomains 
           id={4}
          question={"Is your child  depressed or anxious or has tantrums or other emotional outbursts regularly?"} 
          domains={["affect"]}
           parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          />
           <BrainDomains 
           id={5}
          question={"Is your child unable to read and/or write at a level appropriate for their age?"} 
          domains={["academic"]}
           parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          />
          <BrainDomains 
          id={6}
          question={"Is your child unable to understand and/or use language appropriate for their age? "} 
          domains={["language"]}
          parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          />
          <BrainDomains 
          id={7}
          question={"Is your child unable to problem solve at the speed and complexity appropriate for their age?"} 
          domains={["cognition"]}
           parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          />
            <BrainDomains 
            id={8}
          question={"Is your child unable to remember things regularly (what happened, name of class students etc)?"} domains={['executive', 'memory']}
           domains={["memory"]}
           parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          /> 
          
          <BrainDomains 
          id={9}
          question={"Is your child prone to accidents, unable to perform well things that require delicate hand movements (like handwriting) or tasks that require visual and  body coordination like catching a ball? "} 
          domains={['motor']} 
           parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          /> 
          <BrainDomains 
          id={10}
          question={"is your child reacting in a negative (like tantrum) or possitive way (seeking to experience) at loud sounds, lights, cloth sensations, water on their face or other sensory stimuli "} 
          domains={['sensory']} 
                     parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          /> 
           <BrainDomains 
           id={11}
          question={"Does your child have  physical  health issues (for example heart issues, physical deformities, teeth issues etc)"} 
          domains={['physical']} 
          parentCallBack={childCallBack}
          parentCallBackNotSure={childCallBackNotSure}
          parentCallBackNo={childCallBackNo}
          /> 
          <BrainDomains 
            id={12}
            question={"Does your child have  any known brain structure abnormalities"} 
            domains={['brain']} 
            parentCallBack={childCallBack}
            parentCallBackNotSure={childCallBackNotSure}
            parentCallBackNo={childCallBackNo}
          /> 
          
        
        </Box>
        <Box sx={{ gridArea: 'sidebar', bgcolor: '#34495e' }}>
          possible causes 
            {itemList}
        </Box>
        <Box sx={{ gridArea: 'footer', bgcolor: '#2c3e50' }}>
          All rights reserved (c) 2022 
          <br />
          <br />
          As a parent of children with special needs, I built this tool to help other parents who 
          may find themselves worrying and researching late at night. I hope the tool will help your research and your journey in some way.
          
          <br /><br />
           For my two amazing boys, thank you for helping me a better person every day.
           
           <br/>
           <br />
           Mav
              <br />   <br />   <br />
           
           
           <Link to="/">Diagnostic tool for child developmental delays</Link>,    &nbsp; 
           <Link to="/contact">Provide feedback and get in touch</Link>
           <br />   <br />
          Conditions that can cause children developmental delays: <Link to="/condition/SAS">SAS</Link>
                              
        </Box>
        
        
      </Box>
    </Box>
    </Box>
    </div>
    );
}
