import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Myalert from './Myalert';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
       Attention Deficit Hyperactivity Disorder
      </Typography>
      <Typography variant="h5" component="div">
        ADHD
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
       Neurodevelopmental disorder of childhood. Children with ADHD may have trouble paying attention, controlling impulsive behaviors.  or be overly active.
      </Typography>
      <Typography variant="body2">
          Read more at  
          <Typography variant="title" color="inherit" noWrap>
    &nbsp;
  </Typography>
            <Link 
                href="https://www.adhdaustralia.org.au/about-adhd/what-is-attention-deficit-hyperactivity-disorder-adhd/"
                underline="always" target="new">
                                {'adhdaustralia.org.au'}
                              </Link>
      </Typography>
    </CardContent>
   
  </React.Fragment>
);

const preventDefault = (event) => event.preventDefault();

const debug = false;

export default function OutlinedCard(props) {
  //  console.log('Fasd props', props.results);
    let score = 0;
    let scoreNotSure = 0;
 //   let totalYes = 0;
 //   let totalNo = 0;
    
    for (let [key, value] of Object.entries( props.results)) {
        if (key === "1") {
            if (value === 'yes' || value === 'not sure') { //adaptive
                //  score = score + 1:
                score = score + 1;
            }
        }
        if (key === "2") {
            if (value === 'yes' || value === 'not sure') { //executive
                //  score = score + 1:
                score = score + 1;
            }
        }
        
          if (key === "3") {
            if (value === 'yes' || value === 'not sure') { //attention
                //  score = score + 1:
                score = score + 1;
            }
        }
        
      if(debug === true) {  console.log('yes: '+ score, ', not sure: ' + scoreNotSure ) }
    }
    
    function getContent(score, scoreNotSure){
        if((score+scoreNotSure)>0){
            return (
                  <Box sx={{ minWidth: 200, p:1}}>
                  <Card variant="outlined">{card}
                  <Myalert conditionName={"ADHD"} results={props.results} score={score} scoreNotSure={scoreNotSure} maxScore={3} />
                  </Card>
                </Box>
              );
        }else{
            return(<div />);
        }
    }
    
  return (
     getContent(score, scoreNotSure) 
  );
}
