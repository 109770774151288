import * as React from 'react';


const debug = false;

export function ADHD(results) {
   // console.log('results', results);
    const cause = 'ADHD';
    const maxScore = 3; // max difficulty areas for ADHD

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;



    for (let [key, value] of Object.entries(results)) {
        if (key === "1" || key === "2" || key === "3") {
            if (value === 'yes') { //adaptive
                //  score = score + 1:
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }

        }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }


        if (debug === true) { console.log('cause', cause, 'yes: ' + score, ', not sure: ' + scoreNotSure) }
    }

    /*
    console.log({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
    */

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}


export function ABI(results) {
   // console.log('results', results);
    const cause = 'ABI';
    const maxScore = 12; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;



    for (let [key, value] of Object.entries(results)) {
        if (value === 'yes') { //adaptive
            score = score + 1;
        }
        if (value === 'not sure') {
            scoreNotSure = scoreNotSure + 1;
        }
        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }


        if (debug === true) { console.log('cause', cause, 'yes: ' + score, ', not sure: ' + scoreNotSure) }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function FASD(results) {
   // console.log('results', results);
    const cause = 'FASD';
    const maxScore = 12; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;



    for (let [key, value] of Object.entries(results)) {
        if (value === 'yes') { //adaptive
            score = score + 1;
        }
        if (value === 'not sure') {
            scoreNotSure = scoreNotSure + 1;
        }
        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }


        if (debug === true) { console.log('cause', cause, 'yes: ' + score, ', not sure: ' + scoreNotSure) }
    }
    
    // FASD needs 3 domains minimum 
    if((score + scoreNotSure) < 3){
        score =0;
        scoreNotSure = 0;
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}



export function ANXIETY(results) {
//    console.log('results', results);
    const cause = 'Anxiety';
    const maxScore = 2; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key === "1" || key === "4") {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function ASD(results){
  //   console.log('results', results);
    const cause = 'ASD';
    const maxScore = 5; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;


    for (let [key, value] of Object.entries(results)) {

         if (key === "1" || key === "2" || key === "4" || key === "6" || key === "10") {
             
              if (value === 'yes') { //adaptive
                //  score = score + 1:
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
            
        }
       
        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }

        if (debug === true) { console.log('cause', cause, 'yes: ' + score, ', not sure: ' + scoreNotSure) }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function DEPRESSION(results) {
    //console.log('results', results);
    const cause = 'Depression';
    const maxScore = 2; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key === "1" || key === "4") {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}


export function DMD(results) {
   // console.log('results', results);
    const cause = 'DMD';
    const maxScore = 3; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key === "1" || key === "2" || key === "4"  ) {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}



export function DYSLEXIA(results) {
   // console.log('results', results);
    const cause = 'Dyslexia';
    const maxScore = 4; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="5" || key==="6"  || key==="7" || key==="8" ) {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function ID(results) {
  //  console.log('results', results);
    const cause = 'ID';
    const maxScore = 5; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="1" || key==="2"  || key==="5" || key==="7" || key==="12") {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}


export function IED(results) {
   // console.log('results', results);
    const cause = 'IED';
    const maxScore = 3; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="1" || key==="2"  || key==="4" ) {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}


export function ODD(results) {
   // console.log('results', results);
    const cause = 'ODD';
    const maxScore = 3; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="1" || key==="2"  || key==="4" ) {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function PANDAS(results) {
  //  console.log('results', results);
    const cause = 'PANDAS';
    const maxScore = 6; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="1" || key==="2"  || key==="3" || key==="4"  || key==="9"  || key==="11") {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function RAD(results) {
  //  console.log('results', results);
    const cause = 'RAD';
    const maxScore = 3; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="1" || key==="2"  || key==="4") {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function SLD(results) {
  //  console.log('results', results);
    const cause = 'SLD';
    const maxScore = 3; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="1" || key==="5"  || key==="7") {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function SPD(results) {
   // console.log('results', results);
    const cause = 'SPD';
    const maxScore = 3; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (key==="1" || key==="9"  || key==="10") {
              if (value === 'yes') { //adaptive
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}

export function SAS(results){
     // console.log('results', results);
     /* 
     
        1: 'no', //adaptive 
        2: 'no', //executive
        3: 'no', // attention
        4: 'no', //affect
        5: 'no', // academic
        6: 'no', //language
        7: 'no', //cognition
        8: 'no', //memory 
        9: 'no', //motor
        10: 'no', // sensory
        11: 'no', // physical
        12: 'no', //brain     
     
     1. difficulty with social skills, taking care of themselves, concepts of time, dates or money?
     2. impulsive or is unable to connect cause and effect or claim to not remember things regularly?
     3. unable to maintain their attention to a task at a level considered age appropriate?
     4. depressed or anxious or has tantrums or other emotional outbursts regularly?
     5. unable to read and/or write at a level appropriate for their age?
     6. unable to understand and/or use language appropriate for their age? 
     7. unable to problem solve at the speed and complexity appropriate for their age?
     8. unable to remember things regularly (what happened, name of class students etc)?
     9. prone to accidents, unable to perform well things that require delicate hand movements (like handwriting) or tasks that require visual and body coordination like catching a ball? 
     10. reacting in a negative (like tantrum) or possitive way (seeking to experience) at loud sounds, lights, cloth sensations, water on their face or other sensory stimuli 
     11. have physical health issues (for example heart issues or deformities)
     12. have any known brain structure abnormalities
     
     */
    const cause = 'SAS';
    const maxScore = 6; // max difficulty areas for cause

    let score = 0;
    let scoreNotSure = 0;
    let totalDifficulties = 0;

    for (let [key, value] of Object.entries(results)) {

      if (
            key==="1"  // social skill, adaptive
            || key==="2"  //executive  
            || key==="3"  //executive              
            || key==="10" //sensory
            || key==="6"  //  speech issues a must 
            || key==="11" //physical // teeth abnormalities & palate abormalities a must
            || key==="12" //brain
                 
        ) {
              if (value === 'yes') { 
                score = score + 1;
            }
            if (value === 'not sure') {
                scoreNotSure = scoreNotSure + 1;
            }
      }

        if (value === 'not sure' || value === 'yes') {
            totalDifficulties = totalDifficulties + 1;
        }
    }

    return ({
        cause: cause,
        causeScore: score,
        causeScoreNotSure: scoreNotSure,
        causeTotalScore: (score + scoreNotSure),
        causeMaxScore: maxScore, // max areas of difficulty for condition
        maxDifficulties: totalDifficulties,
        pctMatchTotal: Math.trunc(((score + scoreNotSure) / totalDifficulties) * 100),
        pctMatchCause: Math.trunc(((score + scoreNotSure) / maxScore) * 100),
    });
}