import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Myalert from './Myalert';
import SasCard from './SasCard';

const bull = (
    <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

/*
const card = (
    <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
     SATB2-ASSOCIATED SYNDROME (Also known as Glass syndrome)
      </Typography>
      <Typography variant="h5" component="div">
        SAS 
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
       Inherited genetic disorder causing delay/intellectual disability with absent or limited speech, 
       behavioral problems, and abnormalities of the palate (roof of the mouth) and teeth. 
       Requires genetic testing for diagnosis.
      </Typography>
      <Typography variant="body2">
          Read more: 
          <Typography variant="title" color="inherit" noWrap>
    &nbsp;    <br />  
  </Typography>
  
              <Link 
                href="https://satb2.org.au/"
                underline="always" target="new">
                                {'satb2.org.au'}
                              </Link>
                                   <br />  
                              
   <Link 
                href="https://satb2gene.com/family-what-is-sas/"
                underline="always" target="new">
                                {'satb2gene.com'}
                              </Link>
                              
                       
  

      </Typography>
         &nbsp;    <br />  
        <Typography variant="body2">
          Tools 
          <Typography variant="title" color="inherit" noWrap>
    &nbsp;    <br />  
  </Typography>
  
             
                              
   <Link 
                href="https://facematch.org.au/home"
                underline="always" target="new">
                                {'facematch app'}
                              </Link>
                              <br />FaceMatch aims to help people with a possible genetic condition find a diagnosis by matching their facial features with people who already have a diagnosis.
                       
  

      </Typography>
      
      
      
      
      
    </CardContent>
   
  </React.Fragment>
);
*/
const debug = false;
const preventDefault = (event) => event.preventDefault();

export default function Sas(props) {
   // console.log(props);
    /*
    
       {
     1: 'no', //adaptive 
     2: 'no', //executive
     3: 'no', // attention
     4: 'no', //affect
     5: 'no', // academic
     6: 'no', //language
     7: 'no', //cognition
     8: 'no', //memory 
     9: 'no', //motor
     10: 'no', // sensory
     11: 'no', // physical
     12: 'no', //brain
   }
    
    */
    
    /* 
     sas  calc in causescalculator component
     
            key==="1"  // social skill, adaptive
            || key==="2"  //executive  
            || key==="3"  //executive               
            || key==="10" //sensory
            || key==="6"  //  speech issues a must 
            || key==="11" //physical // teeth abnormalities & palate abormalities a must
            || key==="12" //brain
     
     
     */


    //  console.log('Fasd props', props.results);
    let score = 0;
    let scoreNotSure = 0;
    
    let speechProblems = false;
    let physicalProblems = false;
    
    for (let [key, value] of Object.entries(props.results)) {
        // console.log(key, value);

        if (key === "1") {
            if (value === 'yes' || value === 'not sure') { //affect
                //  score = score + 1:
                score = score + 1;
            }
        }
        if (key === "2") {
            if (value === 'yes' || value === 'not sure') { //affect
                //  score = score + 1:
                score = score + 1;
            }
        }
        if (key === "3") {
            if (value === 'yes' || value === 'not sure') { //affect
                //  score = score + 1:
                score = score + 1;
            }
        }
        if (key === "6") {
            if (value === 'yes' || value === 'not sure') { //language
                //  score = score + 1:
                score = score + 1;
                speechProblems = true;
            }
        }        
        
        if (key === "10") {
            if (value === 'yes' || value === 'not sure') { //affect
                //  score = score + 1:
                score = score + 1;
            }
        }        
        if (key === "11") {
            if (value === 'yes' || value === 'not sure') { //physical
                //  score = score + 1:
                score = score + 1;
                physicalProblems = true;
            }
        }        
          if (key === "12") {
            if (value === 'yes' || value === 'not sure') { //affect
                //  score = score + 1:
                score = score + 1;
            }
        }      

        if (debug === true) { console.log('ASD yes: ' + score, ', not sure: ' + scoreNotSure) }
    }
    
  //  if(props.forceDisplay === false){ // bypass the result  display checks in case we want to display the card  
        if(speechProblems === false || physicalProblems === false ){
            
           // console.log('lets not display things');
            return(<div />);
        }
   // }else{
   //     console.log('display normal');
 //  }
    
    function getAlert(){
        if(props.forceDisplay === true){ // hide myAlert if we are forcing the display of the card as we have no valid results
            return(<div />);
        }else{
            return(
            <Myalert conditionName={"SAS"} results={props.results} score={score} scoreNotSure={scoreNotSure} maxScore={7} />
            );
        }
    }

    function getContent(score, scoreNotSure) {
        if ((score + scoreNotSure) > 1 || props.forceDisplay === true) {
            return (
                <Box sx={{ minWidth: 200, p:1}}>
                  <Card variant="outlined">
                  
                  <SasCard />
                    {getAlert()}
                  </Card>
                </Box>
            );
        }
        else {
            return (<div />)
        }
    }

    return (
        getContent(score, scoreNotSure)
    );
}
