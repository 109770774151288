//import logo from './logo.svg';
//import './App.css';
import BrainDomains from './components/BrainDomains';
import BrainBodyDomains from './BrainBodyDomains';
import Fasd from './components/Fasd';
// jumpstart psychology has a nice table guide
import * as React from 'react';
import ReactGA from 'react-ga';
import {  ThemeProvider, createTheme } from '@mui/system';


//ReactGA.initialize('G-7DBQQJ45CM');
import Amplify from '@aws-amplify/core'
import config from '../src/aws-exports'
Amplify.configure(config)




function App() {
  

      
       ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="App">
        <BrainBodyDomains />
    </div>
  );
}

export default App;
