import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ReactGA from 'react-ga';

const debug = false;
export default function BasicAlerts(props) {

    let totalScore = props.score + props.scoreNotSure;
    if (debug === true) {
        console.log('totalscore', props, totalScore);
    }

    let totalResultsScore = 0;
    let totalYes = 0;
    let totalNotSure = 0;

    for (let [key, value] of Object.entries(props.results)) {
        if (value === 'yes' || value === 'not sure') { //adaptive
            //  score = score + 1:
            totalResultsScore = totalResultsScore + 1;
        }
        if (value === 'yes') { //adaptive
            //  score = score + 1:
            totalYes = totalYes + 1;
        }

        if (value === 'not sure') { //adaptive
            //  score = score + 1:
            totalNotSure = totalNotSure + 1;
        }
    }

    // read about event docs https://github.com/react-ga/react-ga

    ReactGA.event({
        category: 'cause',
        action: props.conditionName + '- yes',
        value: totalYes
    });

    ReactGA.event({
        category: 'cause',
        action: props.conditionName + '- not sure',
        value: totalNotSure
    });



    function getContent() {
        // the condition matches all brain/body difficulties
        if (totalScore <= props.maxScore 
            && props.maxScore >= totalResultsScore 
            && totalScore >= totalResultsScore) {
            return (
                <Alert  severity="success">{props.conditionName}  matches {totalScore} out of {totalResultsScore} difficulty areas.</Alert>
            )
        }
        else {
            return (<Alert severity="warning">{props.conditionName} matches {totalScore} out of {totalResultsScore} difficulty areas, it is possible that the condition may be comorbid with another condition that matches more or all difficulty areas.</Alert>);
        }
    }

    return (
        <Stack sx={{ width: '98%' , p:1}} spacing={2}>
      {getContent()}
      <br />
    </Stack>
    );
}
