import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Myalert from './Myalert';
import SasCard from './SasCard';


const bull = (
    <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

function condition(name){
    if(name=== 'SAS' ){
        return (<SasCard />); //forceDisplace ignores results
    }
}




export default function ConditionViewer(props) {
    console.log('props', props);
    


    function getContent(score, scoreNotSure) {
            return (
                <Box sx={{ minWidth: 200, p:1}}>
                    {condition(props.condition)}
                </Box>
            );
    }

    return (
        getContent(0, 0)
    );
}
